/* .header {
    height: rem(56px);
    margin-bottom: rem(120px);
    background-color: var(--mantine-color-body);
    border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .inner {
    height: rem(56px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .link {
    display: block;
    line-height: 1;
    padding: rem(8px) rem(12px);
    border-radius: var(--mantine-radius-sm);
    text-decoration: none;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
  
    [data-mantine-color-scheme] &[data-active] {
      background-color: var(--mantine-color-blue-filled);
      color: var(--mantine-color-white);
    }
  } */

  .header {
    height: rem(56px);
    margin-bottom: rem(120px);
    background-color: var(--mantine-color-body);
    border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .inner {
    height: rem(56px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

   .link {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    text-decoration: none;
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-weight: 500;
    font-size: var(--mantine-font-size-sm);
  

    @media (max-width: $mantine-breakpoint-sm) {
      height: rem(42px);
      width: 100%;
    }
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
  }

  .subLink {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
    border-radius: var(--mantine-radius-md);
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
    }
  }