.card {
    transition:
      transform 150ms ease,
      box-shadow 150ms ease;
  
    &:hover {
      transform: scale(1.01);
      box-shadow: var(--mantine-shadow-md);
    }
  }
  
  .title {
    font-family: 'Greycliff CF', var(--mantine-font-family);
    font-weight: bold;
  }

  .liveTool {
    color: green;
    /* background-color: lightgreen */
  }